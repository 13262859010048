import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import BookingGraphComponent from '@/components/statistics/booking-graph.vue';
import FinanceTableComponent from '@/components/statistics/finance-table.vue';
import FinanceGraphComponent from '@/components/statistics/finance-graph.vue';

@Component({
    components: {
        BookingGraph: BookingGraphComponent,
        FinanceTable: FinanceTableComponent,
        FinanceGraph: FinanceGraphComponent,
    },
})
export default class PaymentStatistics extends Vue {
    public lastId: number = 3;
    public graphs: Array<{ name: string, id: number }> = [
        { name: 'booking-graph', id: 0 },
        { name: 'booking-graph', id: 1 },
        { name: 'finance-table', id: 2 },
        { name: 'finance-graph', id: 3 },
    ];

    public bookingGraph() {
        this.graphs.push({ name: 'booking-graph', id: this.lastId });
        this.lastId++;
    }

    public financeTable() {
        this.graphs.push({ name: 'finance-graph', id: this.lastId });
        this.lastId++;
    }

    public removeGraph(i: number) {
        this.graphs.splice(i, 1);
    }
}
