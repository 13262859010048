import Component from 'vue-class-component';
import { financeAccountService } from '@/main';
import Vue from 'vue';
import to from 'await-to-js';
import BasePage from '../../models/BasePage';
import { BModal } from 'bootstrap-vue';
import { IVuelidate, validationMixin } from 'vuelidate';
import FinanceAccountModal from '@/components/finance/finance-account-modal';
import financeAccountModalVue from '@/components/finance/finance-account-modal.vue';
import FinanceAccount from '@/models/Finance/FinanceAccount';
import GridWrapperSearch from '@prd/shared-ui/dist/services/components/Grid/models/GridWrapperSearch';

@Component({
    mixins: [validationMixin],
    components: {
        FinanceAccountModal: financeAccountModalVue,
    },
})
export default class FinanceAccountsPage extends BasePage implements IVuelidate<any> {
    public financeAccounts: any[] = [];
    public isLoading: boolean = true;
    public loadingAccounts = false;
    public gridColumns = [
        { field: 'name', title: 'Naam' },
        { field: 'accountNumber', title: 'Accountnummer', width: 250 },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: 100 },
    ];
    public search = new GridWrapperSearch({
        properties: ['name', 'accountNumber'],
    });
    public $refs!: {
        financeAccountModal: FinanceAccountModal,
    };

    public async mounted() {
        await this.initSite();
        this.isLoading = false;

        await this.loadFinanceAccounts();
    }

    public async loadFinanceAccounts() {
        this.loadingAccounts = true;
        const [err, response] = await to(financeAccountService.getAccounts(this.site.siteId));
        if (err) {
            return this.showError('Mislukt om grootboekrekeningen op te halen');
        }
        this.financeAccounts = response.data;
        this.loadingAccounts = false;
    }

    public editFinanceAccount(account: FinanceAccount) {
        this.$refs.financeAccountModal.open(account);
    }

    private renderActions(h: any, a, row) {
        const actions = [{ title: 'Bewerk account', function: this.editFinanceAccount }];
        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }
}
