import Component from 'vue-class-component';
import { dictionaryHelper, invoiceService } from '@/main';
import Vue from 'vue';
import { Watch } from 'vue-property-decorator';
import { Invoice } from '../../models/Invoice';
import InvoiceBasePage from '../../models/InvoiceBasePage';
import { BModal } from 'bootstrap-vue';
import to from 'await-to-js';
import { $router } from '@/router';
import moment from 'moment/min/moment.min.js';
import { InvoiceState } from '@/models/InvoiceState';

@Component
export default class InvoicesPage extends InvoiceBasePage {
    public invoices: Invoice[] = [];
    public allInvoices: Invoice[] = [];
    public invoiceStateIds: any[] = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }];
    public filteredInvoiceState: number = null;
    public invoiceNumber: string = '';
    public filteredRegisterSale: boolean = null;

    public newInvoiceState: number = null;
    public InvoiceIdForStateChange: number = null;
    public newInvoiceStateAmount: string = '0';
    public paymentMethod: string = null;

    public take: number = 25;
    public skip: number = 0;

    public filterLoading: boolean = false;
    public selectedProperty: string = 'selected';

    public gridColumns = [
        { field: 'selected', width: 60, locked: true, filterable: false, className: 'bg-white' },
        { field: 'invoiceNumber', cell: this.renderInvoiceLink, title: 'Factuurnummer' },
        { cell: this.renderLink, title: 'Boeking' },
        { field: 'invoiceDate', title: 'Factuurdatum', cell: this.renderInvoiceDate },
        { field: 'dueDate', title: 'Verloopdatum', cell: this.renderDueDate },
        // { field: 'mainBooker', title: 'Hoofdboeker', cell: this.renderMaindbookerLink },
        { field: 'totalAmount', title: 'Bedrag', cell: this.renderValuta },
        { title: 'Openstaand bedrag', cell: this.renderOpenstaandValuta },
        { title: 'Status', cell: this.renderStatus },
        { title: 'Is crediteer factuur?', cell: this.renderCredit, filterable: false, width: 150 },
        { title: 'Kanaal', field: 'bookingChannel', filterable: false, width: 150 },
        { title: 'Bron', field: 'bookingSource', filterable: false, width: 150 },
        { title: 'Acties', cell: this.renderActions, filterable: false, width: 150 },
    ];

    public $refs!: {
        invoiceStatusModal: BModal;
        paidModal: BModal;
    };

    @Watch('filteredInvoiceState')
    public async OnStateChanged(val, oldVal) {
        if (val && !this.filterLoading) {
            this.filterLoading = true;
            this.invoices = [];
            this.invoices = await this.loadInvoices(null, null);
            this.filterLoading = false;
        }
    }

    @Watch('filteredRegisterSale')
    public async OnRegisterChanged(val, oldVal) {
        if (val !== oldVal && !this.filterLoading) {
            this.filterLoading = true;
            this.invoices = [];
            this.invoices = await this.loadInvoices(null, null);
            this.filterLoading = false;
        }
    }

    public async created() {
        await this.initSite();
    }

    public getData() {
        return this.invoices.slice(this.skip, this.take + this.skip);
    }

    public async loadInvoices(startMonth, endMonth) {
        const [err, response] = await to(
            invoiceService.getInvoices(
                this.site.siteId,
                null,
                this.filteredInvoiceState,
                null,
                null,
                null,
                this.invoiceNumber,
                this.filteredRegisterSale,
                null,
                startMonth,
                endMonth,
            ),
        );
        if (err) {
            this.showError('Mislukt om de facturen op te halen');
        }
        return response.data;
    }

    public getSelectedInvoices() {
        return this.invoices.filter((item) => item[this.selectedProperty] === true);
    }

    public async reloadInvoices(dates) {
        this.isLoading = true;
        this.allInvoices = this.invoices = await this.loadInvoices(dates.fromFilterMonth, dates.toFilterMonth);

        this.isLoading = false;
    }

    public onHeaderSelectionChange(event) {
        const checked = event.event.target.checked;
        Vue.set(
            this,
            'invoices',
            this.invoices.map((item) => ({ ...item, selected: checked })),
        );
    }

    public onSelectionChange(event) {
        Vue.set(event.dataItem, this.selectedProperty, !event.dataItem[this.selectedProperty]);
    }

    public async sendReminders() {
        this.showPending('Factuur herinneringen versturen...');

        const invoiceIds = this.getSelectedInvoices()
            .filter((x) => x.mainBookerId)
            .map((x) => x.invoiceId);

        if (invoiceIds.length > 0) {
            const [err, response] = await to(invoiceService.sendReminders(invoiceIds));
            if (err) {
                return this.clearAndShowError('Factuur herinneringen versturen mislukt.', err);
            }

            this.clearAndShowSuccess('Factuur herinneringen verstuurd.');
        } else {
            this.clearNotifications();
        }

        if (this.getSelectedInvoices().filter((x) => !x.mainBookerId)) {
            this.showWarning(
                `Bepaalde facturen (${this.getSelectedInvoices()
                    .filter((x) => !x.mainBookerId)
                    .map((x) => x.invoiceNumber)
                    .join(',')})  bevatten geen hoofdboeker. Hiervoor kan geen herinnering voor worden verstuurd.`,
            );
        }
    }

    public async addNewInvoice() {
        $router.push({
            name: 'invoice-create',
            params: { siteKey: this.siteKey, invoiceId: null },
        });
    }

    public get total() {
        return this.invoices ? this.invoices.length : 0;
    }

    public update(dataItems) {
        this.invoices = dataItems;
    }

    public pageChangeHandler(event) {
        this.skip = event.page.skip;
        this.take = event.page.take;
    }

    public renderOpenstaandValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.totalAmount - row.dataItem.amountPaid, 'c'));
    }

    public renderValuta(h: any, a, row) {
        return h('td', kendo.toString(row.dataItem.totalAmount, 'c'));
    }

    public renderCredit(h, a, row) {
        return h('td', [row.dataItem.isCreditInvoice ? 'Ja' : 'Nee']);
    }

    public renderStatus(h: any, a, row) {
        return h('td', dictionaryHelper.get('InvoiceStates')[row.dataItem.status]);
    }

    public renderLink(h: any, a, row) {
        if (!row.dataItem.bookingId) {
            return h('td', 'Niet gekoppeld');
        }

        const route = $router.resolve({
            name: 'booking',
            params: { siteKey: this.siteKey, bookingKey: row.dataItem.bookingId },
        });

        const props = { text: 'bk-' + row.dataItem.bookingId, url: route.href };

        return h(Vue.component('grid-router-link'), { props });
    }

    public renderInvoiceLink(h: any, a, row) {
        const route = $router.resolve({
            name: row.dataItem.status === 1 ? 'invoice-create' : 'invoice',
            params: { siteKey: this.siteKey, invoiceId: row.dataItem.invoiceId },
        });

        const props = { text: row.dataItem.invoiceNumber, url: route.href };
        return h(Vue.component('grid-router-link'), { props });
    }

    public async sendAsPdf() {
        throw new Error('Method not implemented');
    }

    public optionsListStates() {
        const list = this.invoiceStateIds.map((state) => {
            return { value: state.id, text: dictionaryHelper.get('InvoiceStates')[state.id] };
        });
        return list;
    }

    public optionsListRegisterSale() {
        return [
            { value: true, text: 'Losse verkoop' },
            { value: false, text: 'Boekingen' },
        ];
    }

    public async filterInvoiceNumber() {
        this.invoices = await this.loadInvoices(null, null);
    }

    public paymentRecieved(invoice) {
        this.InvoiceIdForStateChange = invoice.invoiceId;
        this.$refs.invoiceStatusModal.show();
    }

    public async changeInvoiceStatusWithModalHide() {
        const amoundPaid = this.newInvoiceState === 3 ? this.newInvoiceStateAmount : null;
        await this.makeInvoicePayment(this.InvoiceIdForStateChange, this.site.siteId, this.paymentMethod, amoundPaid);

        const foundInvoice = this.invoices.find((invoice) => invoice.invoiceId === this.InvoiceIdForStateChange);
        if (this.newInvoiceState === InvoiceState.PartiallyPaid) {
            foundInvoice.amountPaid = parseInt(this.newInvoiceStateAmount, 10);
            foundInvoice.status = this.newInvoiceState;
        } else if (this.newInvoiceState === InvoiceState.Paid) {
            foundInvoice.amountPaid = foundInvoice.totalAmount;
            foundInvoice.status = this.newInvoiceState;
        }

        this.$refs.invoiceStatusModal.hide();
    }

    public openPaidModal() {
        this.$refs.paidModal.show();
    }

    public renderInvoiceDate(h: any, a, row) {
        return h('td', moment(row.dataItem.invoiceDate, 'YYYY-MM-DD').format('DD-MM-YYYY'));
    }

    public renderDueDate(h: any, a, row) {
        if (row.dataItem.dueDate) {
            return h('td', moment(row.dataItem.dueDate).format('DD-MM-YYYY'));
        }

        return h('td', '-');
    }

    public async deleteInvoice(invoice) {
        this.showPending('Factuur verwijderen...');

        const [err, response] = await to(invoiceService.deleteInvoice(invoice.invoiceId));
        if (err) {
            return this.clearAndShowError('Factuur verwijderen mislukt.', err);
        }

        this.clearAndShowSuccess('Factuur verwijderd.');
    }

    public async changeInvoiceStatus(invoiceId, siteId, newState): Promise<boolean> {
        this.showPending('Factuur updaten...');

        const [err, response] = await to(invoiceService.updateInvoiceState(invoiceId, siteId, newState));
        if (err) {
            this.clearAndShowError('Factuur updaten mislukt.', err);
            return false;
        }

        this.clearAndShowSuccess('Factuurstatus geupdatet.');
        return true;
    }

    public async makeInvoicePayment(invoiceId, siteId, paymentMethod, paidAmount?: string) {
        this.showPending('Factuurbetaling verwerken...');
        const [err, response] = await to(invoiceService.makeInvoicePayment(invoiceId, siteId, paymentMethod, paidAmount));
        if (err) {
            return this.clearAndShowError('Factuurbetaling mislukt.', err);
        }

        this.clearAndShowSuccess('Factuurbetaling geupdatet.');
    }

    public async downloadInvoice(invoice) {
        const self = this;

        self.showPending('Factuur download voorbereiden...');

        const [err, response] = await to(invoiceService.downloadInvoice(invoice.invoiceId));
        if (err) {
            return self.clearAndShowError('Factuur downloaden mislukt.', err);
        }

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${invoice.invoiceNumber}-${new Date().toLocaleTimeString()}.pdf`);
        document.body.appendChild(link);
        link.click();

        self.clearAndShowSuccess('Factuur gedownload.');
    }

    public showInvoiceStatusChangeModal(invoice) {
        this.InvoiceIdForStateChange = invoice.invoiceId;
        this.$refs.invoiceStatusModal.show();
    }

    public async createCreditInvoice(invoice) {
        this.showPending('Factuur crediteren...');

        const [err, response] = await to(invoiceService.createCreditInvoice(invoice.invoiceId));
        if (err) {
            return this.clearAndShowError('Factuur credtieren mislukt.', err);
        }

        this.clearAndShowSuccess('Creditfactuur aangemaakt.');
    }

    public async bookInvoice(invoice: Invoice) {
        const success = await this.changeInvoiceStatus(invoice.invoiceId, this.site.siteId, InvoiceState.Open);
        if (success) {
            invoice.status = 2;
        }
    }

    public async sendIdealLink(invoice: any) {
        const invoiceId = invoice.invoiceId;

        this.showPending('Betaal link genereren en versturen..');

        const [err, response] = await to(invoiceService.sendIdealLinkMail(invoiceId));
        if (err) {
            return this.clearAndShowError('Betaal link genereren en versturen is mislukt', err);
        }

        invoice.status = 2;

        this.clearAndShowSuccess('Betaal link succesvol verstuurd.');
        this.editMode = false;
    }

    private renderActions(h: any, a, row) {
        const status = row.dataItem.status;
        const actions = [];

        if (status === 6) {
            return h('td');
        }

        if (status === 1) {
            actions.push({ title: 'Boek factuur', function: this.bookInvoice });
            actions.push({ title: 'Verwijder factuur', function: this.deleteInvoice });
        }

        if (status >= 2 && status !== 6) {
            actions.push({ title: 'Download factuur als PDF', function: this.downloadInvoice });
        }

        if (status >= 2 && status <= 5 && status !== 4) {
            actions.push({ title: 'Zet status (deels) betaald', function: this.showInvoiceStatusChangeModal });
        }

        if (status >= 2 && status <= 5 && !row.dataItem.isCreditInvoice) {
            actions.push({ title: 'Crediteer', function: this.createCreditInvoice });
        }

        if (status >= 2 && status <= 5 && status !== 4) {
            actions.push({ title: 'Stuur betaal link', function: this.sendIdealLink });
        }

        const item = row.dataItem;
        const props = { item, actions };

        return h(Vue.component('grid-actions'), { props });
    }
}
