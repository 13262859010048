export enum StatisticsBookingState {
    Unknown = 0,
    Reserved = 1,
    AffiliateReserved = 2,
    Confirmed = 3,
    Rejected = 4,
    Paid = 5,
    CheckedIn = 6,
    CheckedOut = 7,
    Canceled = 8,
}
