import { statsService } from '@/main';
import { StatisticsBookingState } from '@/models/Statistics/BookingState';
import StatisticsData from '@/models/Statistics/Data';
import StatisticsGroup from '@/models/Statistics/Group';
import { StatisticsGroupBy } from '@/models/Statistics/GroupBy';
import StatisticsParams from '@/models/Statistics/Params';
import { sitesModule } from '@/store/modules/site';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import StatisticsGraphContainerComponent from './graph-container.vue';
import { GChart } from 'vue-google-charts';

@Component({
    components: {
        GraphContainer: StatisticsGraphContainerComponent,
        GChart,
    },
})
export default class BookingGraphComponent extends Vue {
    public bookingsState: StatisticsBookingState = StatisticsBookingState.Paid;
    public groupBy: StatisticsGroupBy = StatisticsGroupBy.Month;
    public chartData: number[] = [];
    public chartOptions = {
        legend: { position: 'bottom' },
        hAxis: { gridlines: { count: 12 } },
        height: 300,
        chartArea: {
            top: 10,
            height: 250,
            width: '90%',
        },
    };
    public groupByType = StatisticsGroupBy;
    public $refs!: {
        container: StatisticsGraphContainerComponent,
    };

    public get siteId() {
        return sitesModule.activeSite.siteId;
    }

    public get periodLengths(): any {
        const lengths = {};
        lengths[StatisticsGroupBy.Month] = 12;
        lengths[StatisticsGroupBy.Week] = 52;
        return lengths;
    }

    public mapChartData(data: { stats: StatisticsData[] }) {
        const columns = ['Jaar'];
        const rows = [];
        const lines = [];
        data.stats.forEach((stat) => {
            const line: any = {};
            columns.push(stat.name);
            stat.groups.forEach((group: StatisticsGroup) => {
                line[group.groupKey] = group.groupCount;
            });
            lines.push(line);
        });

        for (let i = 0; i < this.periodLengths[this.groupBy]; i++) {
            const label = this.renderLabel(i);
            const row = [label];
            for (let l = 0; l < lines.length; l++) {
                row.push(lines[l] && lines[l][i] ? lines[l][i] : 0);
            }
            rows.push(row);
        }
        this.chartOptions.hAxis.gridlines.count = this.periodLengths[this.groupBy];
        this.chartData = lines.length ? [columns, ...rows] : [];
    }

    public async callback(params: StatisticsParams) {
        return statsService.bookings(params);
    }

    public async refresh() {
        await this.$nextTick();
        (this.$refs.container as any).loadStats();
    }

    private renderLabel(index: number) {
        switch (this.groupBy) {
            case StatisticsGroupBy.Month:
                return new Date(0, index + 1, 0).toLocaleDateString('default', { month: 'short' });
            default:
                return index;
        }
    }
}
