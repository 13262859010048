import Component from 'vue-class-component';
import BasePage from '@/models/BasePage';
import { siteInvoiceDataService, financeAccountService, productService } from '@/main';
import to from 'await-to-js';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { IVuelidate, validationMixin } from 'vuelidate';

@Component({
    mixins: [validationMixin],
    components: {},
    validations: {
        siteInvoiceData: {
            btwNumber: { minLength: minLength(14), maxLength: maxLength(14) },
            cancelFinanceAccount: { required },
            cancellationVat: { required },
            city: { required },
            email: { required },
            houseNumber: { required },
            ibanNumber: { minLength: minLength(15), maxLength: maxLength(32) },
            kvkNumber: { required },
            phoneNumber: { required },
            postalCode: { required },
            registeredCompanyName: { required },
            residenceFinanceAccount: { required },
            residenceVat: { required },
            streetName: { required },
            energyFinanceAccount: { required },
            gasFinanceAccount: { required },
            waterFinanceAccount: { required },
        },
    },
})
export default class SiteInvoiceDataComponent extends BasePage implements IVuelidate<any> {
    public siteInvoiceData: any = {};
    public isLoading: boolean = true;
    public editMode: boolean = false;
    public financeAccounts: any[] = [];
    public vats: any[] = [];

    public async created() {
        await this.initSite();
        await Promise.all([this.getSiteInvoiceData(), this.loadFinanceAccounts(), this.loadVATs()]);

        this.isLoading = false;
    }

    public async getSiteInvoiceData() {
        const [err, response] = await to(siteInvoiceDataService.getSiteInvoiceData(this.site.siteId));
        if (err) {
            return this.showFailedResponse('Mislukt om de factuurgegevens op te halen.', err);
        }

        if (response.status !== 204) {
            this.siteInvoiceData = response.data;
        }
    }

    public async loadFinanceAccounts() {
        const [err, response] = await to(financeAccountService.getAccounts(this.site.siteId));
        if (err) {
            this.showError('Grootboekrekeninge ophalen mislukt');
        }
        return (this.financeAccounts = response.data);
    }

    public async loadVATs() {
        const [err, response] = await to(productService.getVATs());
        if (err) {
            this.showError('BTW-schalen ophalen mislukt');
        }
        return (this.vats = response.data);
    }

    public async save() {
        this.showPending('Factuurgegevens opslaan..');
        if (!this.validateObject('siteInvoiceData')) {
            return this.clearAndShowError('Niet alle verplichte velden zijn ingevuld');
        }

        this.siteInvoiceData.siteId = this.site.siteId;
        this.siteInvoiceData.residenceFinanceAccountId = this.siteInvoiceData.residenceFinanceAccount.financeAccountId;
        this.siteInvoiceData.residenceVatId = this.siteInvoiceData.residenceVat.vatId;
        this.siteInvoiceData.cancelFinanceAccountId = this.siteInvoiceData.cancelFinanceAccount.financeAccountId;
        this.siteInvoiceData.cancellationVatId = this.siteInvoiceData.cancellationVat.vatId;

        this.siteInvoiceData.gasFinanceAccountId = this.siteInvoiceData.gasFinanceAccount.financeAccountId;
        this.siteInvoiceData.energyFinanceAccountId = this.siteInvoiceData.energyFinanceAccount.financeAccountId;
        this.siteInvoiceData.waterFinanceAccountId = this.siteInvoiceData.waterFinanceAccount.financeAccountId;
        this.siteInvoiceData.gasDepositFinanceAccountId = this.siteInvoiceData.gasDepositFinanceAccount.financeAccountId;
        this.siteInvoiceData.energyDepositFinanceAccountId = this.siteInvoiceData.energyDepositFinanceAccount.financeAccountId;
        this.siteInvoiceData.waterDepositFinanceAccountId = this.siteInvoiceData.waterDepositFinanceAccount.financeAccountId;
        this.siteInvoiceData.gasVatId = this.siteInvoiceData.gasVat.vatId;
        this.siteInvoiceData.energyVatId = this.siteInvoiceData.energyVat.vatId;
        this.siteInvoiceData.waterVatId = this.siteInvoiceData.waterVat.vatId;

        if (!this.siteInvoiceData.siteInvoiceDataId) {
            const [err, response] = await to(siteInvoiceDataService.postSiteInvoiceData(this.siteInvoiceData));

            if (err) {
               return this.clearAndShowError('Factuurgegevens opslaan mislukt');
            }
        } else {
            const [err, response] = await to(siteInvoiceDataService.updateSiteInvoideData(this.siteInvoiceData));
            if (err) {
                return this.clearAndShowError('Factuurgegevens opslaan mislukt');
            }
        }

        this.editMode = false;
        this.clearAndShowSuccess('Factuurgegevens succesvol opgeslagen');
    }
}
