export default class FinanceAccount {
    public name: string = '';
    public accountNumber: string = '';
    public financeAccountId: number = 0;
    public siteIds: number[] = [];

    constructor(obj?: Partial<FinanceAccount>) {
        if (obj) {
            Object.assign(obj);
        }
    }
}
