import { statsService } from '@/main';
import StatisticsParams from '@/models/Statistics/Params';
import { sitesModule } from '@/store/modules/site';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import StatisticsGraphContainerComponent from './graph-container.vue';

@Component({
    components: {
        GraphContainer: StatisticsGraphContainerComponent,
    },
})
export default class FinanceTableComponent extends Vue {
    public get siteId() {
        return sitesModule.activeSite.siteId;
    }

    public get siteKey() {
        return sitesModule.activeSite.siteKey;
    }

    public async callback(params: StatisticsParams) {
        return await statsService.finance(params);
    }
}
