import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { GChart } from 'vue-google-charts';
import { StatisticsGroupBy } from '@/models/Statistics/GroupBy';
import StatisticsPeriod from '@/models/Statistics/Period';
import StatisticsData from '@/models/Statistics/Data';
import StatisticsResponse from '@/models/Statistics/Response';
import { dateHelper, siteService } from '@/main';
import StatisticsParams from '@/models/Statistics/Params';
import StatisticsGroup from '@/models/Statistics/Group';
import PlaceType from '@/models/PlaceType';
import Place from '@/models/Place';

@Component({
    components: {
        GChart,
    },
})
export default class StatisticsGraphContainerComponent extends Vue {
    @Prop() public title: string;
    @Prop() public siteId: number;
    @Prop() public siteKey: string;
    @Prop() public callback: (params: StatisticsParams) => Promise<StatisticsResponse>;
    @Prop() public params: StatisticsParams;
    @Prop({ type: Boolean }) public disableTable: boolean;
    @Prop({ type: Boolean }) public placeFilter: boolean;

    public periods: StatisticsPeriod[] = [{
        from: dateHelper.lastYear(),
        to: new Date(),
        name: 'Periode 1',
    }];
    public showPeriods: boolean = true;
    public showTable: boolean = true;
    public showPlaceFilter: boolean = false;
    public groupBy: StatisticsGroupBy = StatisticsGroupBy.Month;
    public colSize: number = 6;
    public loading: boolean = false;
    public stats: StatisticsData[] = [];
    public statsTable: number[][] = [];
    public chartData: number[] = [];
    public placeType = new PlaceType();
    public place = new Place();
    public loadingPlaceOptions = false;
    public placeTypeOptions: PlaceType[] = [];
    public allPlaceOptions: Place[] = [];

    public get placeOptions() {
        if (this.placeType && this.placeType.placeTypeId) {
            return this.allPlaceOptions.filter((place: Place) => {
                return place.placeTypeId === this.placeType.placeTypeId;
            });
        }
        return [];
    }

    public async mounted() {
        this.loadStats();

        if (this.placeFilter) {
            this.loadPlaceTypeOptions();
        }
    }

    public async loadStats() {
        if (this.callback) {
            this.loading = true;
            this.stats = [];
            for (let i = 0; i < this.periods.length; i++) {
                const period = this.periods[i];
                const response: StatisticsResponse = await this.callback(this.getStatisticsParams(period));
                this.stats.push({
                    ...response,
                    name: period.name,
                });
            }
            this.statsTable = this.mapStatsTable(this.stats);
            this.$emit('loaded', { stats: this.stats, groupBy: this.groupBy });
            this.loading = false;
        }
    }

    public removePeriod(index: number) {
        this.periods.splice(index, 1);
        this.loadStats();
    }

    public addPeriod() {
        this.periods.push({
            from: dateHelper.lastYear(),
            to: new Date(),
            name: 'Periode ' + (this.periods.length + 1),
        });
    }

    public togglePeriods() {
        this.showPeriods = !this.showPeriods;
    }

    public toggleTable() {
        this.showTable = !this.showTable;
    }

    public placeLabel(place: Place) {
        if (place.lotNumber) {
            return `${place.name} (${place.lotNumber})`;
        }
        return place.name;
    }

    private mapStatsTable(stats: StatisticsData[]): any[][] {
        const keys = [];
        const rows = [];
        stats.forEach((stat: StatisticsData) => {
            const columns: { [key: string]: number | string } = {};
            stat.groups.forEach((g: StatisticsGroup) => {
                if (keys.indexOf(g.groupKey) < 0) {
                    keys.push(g.groupKey);
                }
                columns[g.groupKey] = g.groupCount;
            });
            rows.push(columns);
        });
        const sortedKeys = keys.sort((a: any, b: any) => {
            if (isNaN(a) && isNaN(b)) {
                const aStr = `${a}`.toLowerCase();
                const bStr = `${b}`.toLowerCase();
                return aStr < bStr ? -1 : aStr > bStr ? 1 : 0;
            }
            return a < b ? -1 : a > b ? 1 : 0;
        });

        const table = [];
        for (let r = 0; r < keys.length; r++) {
            const key = sortedKeys[r];
            const tableRow = [];
            tableRow.push(key);
            for (let c = 0; c < stats.length; c++) {
                if (rows[c] && rows[c][key]) {
                    tableRow.push(rows[c][key]);
                } else {
                    tableRow.push('-');
                }
            }
            table.push(tableRow);
        }

        return table;
    }

    private async loadPlaceTypeOptions() {
        if (!this.siteKey) {
            throw Error('Site key is required to retreive placetypes');
        }

        this.loadingPlaceOptions = true;
        this.placeTypeOptions = (await siteService.getPlaceTypes(this.siteKey)).data;
        this.allPlaceOptions = (await siteService.getPlaces(this.siteKey)).data;
        this.loadingPlaceOptions = false;
    }

    private getStatisticsParams(period: StatisticsPeriod): StatisticsParams {
        const params = {
            ...this.params,
            siteId: this.siteId,
            fromDate: dateHelper.format(period.from, 'YYYY-MM-DD'),
            toDate: dateHelper.format(period.to, 'YYYY-MM-DD'),
        };

        if (this.placeFilter) {
            if (this.placeType && this.placeType.placeTypeId) {
                params.place_type_id = this.placeType.placeTypeId;
            }

            if (this.place && this.place.placeId) {
                delete params.place_type_id;
                params.place_type = this.place.placeId;
            }
        }

        return params;
    }
}
