import { statsService } from '@/main';
import StatisticsParams from '@/models/Statistics/Params';
import { sitesModule } from '@/store/modules/site';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import StatisticsGraphContainerComponent from './graph-container.vue';
import { GChart } from 'vue-google-charts';
import StatisticsData from '@/models/Statistics/Data';
import StatisticsGroup from '@/models/Statistics/Group';

@Component({
    components: {
        GraphContainer: StatisticsGraphContainerComponent,
        GChart,
    },
})
export default class FinanceGraphComponent extends Vue {
    public chartData: any[] = [];
    public showChart = false;
    public chartOptions = {
        hAxis: { format: 'decimal' },
        height: 400,
        colors: ['#228be6'],
    };

    public get siteId() {
        return sitesModule.activeSite.siteId;
    }

    public get siteKey() {
        return sitesModule.activeSite.siteKey;
    }

    public async callback(params: StatisticsParams) {
        return await statsService.finance(params);
    }

    public mapChartData(data: { stats: StatisticsData[] }) {
        const columns = ['Grootboekrekening'];
        const rows = [];
        const lines = [];
        const groupKeys: string[] = [];

        data.stats.forEach((stat: StatisticsData) => {
            const line: any = {};
            columns.push(stat.name);
            stat.groups.forEach((group: StatisticsGroup) => {
                line[group.groupKey] = +group.groupCount;
                if (groupKeys.indexOf(group.groupKey) < 0) {
                    groupKeys.push(group.groupKey);
                }
            });
            lines.push(line);
        });

        groupKeys.sort((a: string, b: string) => {
            return a < b ? -1 : a > b ? 1 : 0;
        });

        for (let i = 0; i < groupKeys.length; i++) {
            const label = groupKeys[i];
            const row = [label];
            for (let l = 0; l < lines.length; l++) {
                row.push(lines[l] && lines[l][label] ? lines[l][label] : 0);
            }
            rows.push(row);
        }
        this.showChart = rows.length > 0;
        this.chartData = [columns, ...rows];
    }
}
