import { financeAccountService } from '@/main';
import FinanceAccount from '@/models/Finance/FinanceAccount';
import PageRender from '@/models/PageRender';
import Site from '@/models/site';
import to from 'await-to-js';
import { BModal } from 'bootstrap-vue';
import { Component, Prop } from 'vue-property-decorator';
import { required } from 'vuelidate/lib/validators';

@Component({
    validations: {
        account: {
            name: { required },
            accountNumber: { required },
        },
    },
})
export default class FinanceAccountModal extends PageRender {
    @Prop({ type: Object, required: true }) public site: Site;
    public account = new FinanceAccount();
    public checkingDuplicates = false;
    public matchingAccounts: FinanceAccount[] = [];
    public $refs!: {
        modal: BModal,
        confirm: BModal,
    };

    public get title(): string {
        return !this.account.financeAccountId ?
            'Grootboekrekening aanmaken' :
            'Grootboekrekening bewerken';
    }

    public open(account?: FinanceAccount) {
        if (account) {
            this.account = account;
        } else {
            this.account = new FinanceAccount();
            this.account.siteIds = [this.site.siteId];
        }

        this.dirty = false;
        this.$refs.modal.show();
    }

    public async save() {
        if (!this.account.financeAccountId) {
            const { success, matching } = await this.checkDuplicate();
            this.matchingAccounts = matching;

            if (success && matching.length === 0) {
                await this.saveAccount();
                this.close();
            }
        } else {
            await this.saveAccount();
            this.close();
        }
    }

    public close() {
        this.dirty = false;
        this.account = new FinanceAccount();
        this.checkingDuplicates = false;
        this.matchingAccounts = [];
        this.$refs.modal.hide();
    }

    private async checkDuplicate(): Promise<{ success: boolean, matching: FinanceAccount[] }> {
        this.checkingDuplicates = true;
        const [err, response] = await to(financeAccountService.getAccounts());
        if (err) {
            this.clearAndShowError('Mislukt om alle grootboekrekeningen op te halen.');
            return { success: false, matching: [] };
        }
        const allAccounts = response.data as FinanceAccount[];
        const matching = allAccounts.filter((acc: FinanceAccount) => {
            return acc.siteIds.indexOf(this.site.siteId) > -1 && (
                acc.accountNumber.toLowerCase() === this.account.accountNumber.toLowerCase() ||
                acc.name.toLowerCase() === this.account.name.toLowerCase()
            );
        });
        this.checkingDuplicates = false;
        return { success: true, matching };
    }

    private async saveAccount() {
        this.clearNotifications();
        this.showPending('Grootboekrekening opslaan...');
        let err = null;
        let response = null;

        if (!this.validateObject('account')) {
            return this.clearAndShowError('Niet alle verplichte velden zijn ingevuld.');
        }

        if (!this.account.financeAccountId) {
            [err, response] = await to(financeAccountService.createFinanceAccount(this.account));
        } else {
            [err, response] = await to(financeAccountService.saveFinanceAccount(this.account));
        }

        if (err) {
            this.clearAndShowError('Mislukt om grootboekrekening op te slaan.');
        } else {
            this.clearAndShowSuccess('Grootboekrekening succesvol opgeslagen.');
            this.$emit('saved', response);
        }
    }
}
